body {
  overscroll-behavior: none !important;
  -webkit-overflow-scrolling: none !important;
  margin: 0;
  padding: 0;
  touch-action: none !important;
  user-select: auto;
  -webkit-user-drag: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}